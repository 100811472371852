import styled from "styled-components";
import { Common } from "theme/commonStyle";
import { mediaQuery } from "theme/mediaQuery";
import logout from "assets/icon/logout.svg";
const ProfileInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px dashed ${(p) => p.theme.lineColor.line3};
  padding-bottom: 30px;
  gap: 30px;
  margin-bottom: 60px;
  .infoBox {
    p {
      font-size: 1.4rem;
      color: ${(p) => p.theme.textColor.textColor60};
      padding-bottom: 20px;
    }
    .chipBox {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 6px;
    }
    .chip {
      padding: 10px 14px;
      background-color: ${(p) => p.theme.palette.light30};
      border-radius: 4px;
      color: ${(p) => p.theme.textColor.textColor80};
      font-size: 12px;
      strong {
        font-size: inherit;
        color: ${(p) => p.theme.textColor.textColor30};
      }
    }
  }

  ${mediaQuery("mobile")} {
    margin-bottom: 50px;
  }
`;

const ButtonBox = styled(Common.MainButtonBox)`
  padding-top: 100px;
`;

const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  height: 34px;
  font-size: 1.2rem;
  color: ${(p) => p.theme.textColor.textColor70};
  white-space: nowrap;
  cursor: pointer;
  &:before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    background-size: 100%;
    background-image: url(${logout});
  }

  &:hover {
    background-color: ${(p) => p.theme.bgColor.bg1};
  }
`;
export const PS = {
  ProfileInfoContainer,
  ButtonBox,
  LogoutButton,
};
