import { apiHeader } from 'api/constant'
import { GenericResponse } from 'model/common'
import { TermFilterOption } from 'model/term'

const Term = {
    getTermVersion: async (params: TermFilterOption) => {
        const response = await apiHeader(true, 'b').get<GenericResponse>(`term/version?termType=${params.termType}`)
        return response.data
    },
    getTerm: async (params: TermFilterOption) => {
        const response = await apiHeader(true, 'b').get<GenericResponse>(`term`, { params })
        return response.data
    },
}

export default Term
