import moment from "moment";

const EMAIL =
  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
// const PASSWORD = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
const PASSWORD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;

const PHONE = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;

const URL =
  /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
const ACCOUNT = /^[0-9-]+$/;
const PHONE_LENGTH = (value: string, maxlength: number) => {
  if (value.length > maxlength) {
    return value.substring(0, maxlength);
  }
};
export const VALIDATION = {
  EMAIL,
  PASSWORD,
  PHONE,
  PHONE_LENGTH,
  URL,
  ACCOUNT,
};

export const phoneToHypheon = (phone: string) => {
  return phone
    .replace(/\D/g, "")
    .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, "$1-$2-$3");
};

export const dateToMomentHypeon = (
  date: Date | null | undefined,
  hour?: boolean
) => {
  if (!date) return null;
  return date && hour
    ? moment(date).format("YYYY-MM-DD HH:mm")
    : moment(date).format("YYYY-MM-DD");
};

export const convertTextFigkStatus = (e: string) => {
  if (!e) return "미송고";
  return e === "E"
    ? "송고 완료"
    : e === "N"
    ? "미송고"
    : e === "P"
    ? "당선"
    : e === "F"
    ? "낙선"
    : e === "T"
    ? "반려"
    : "투표마감";
};

export const convertTextFigkStatusColor = (e: string) => {
  if (!e) return "gray40";
  return e === "E"
    ? "gray90"
    : e === "N"
    ? "gray40"
    : e === "P"
    ? "positive"
    : e === "C"
    ? "gray40"
    : "error";
};

export const 에디터특수기호변경 = (str: string) => {
  // str = str?.replaceAll('&lt;', '<')
  // str = str?.replaceAll('&gt;', '>')
  // str = str?.replaceAll('&amp;', '&')
  // return str
  if (str) {
    return str
      .replace(/&nbsp;/g, "")
      .replace(/&amp;/g, "&")
      .replace(/&gt;/g, ">")
      .replace(/&lt;/g, "<")
      .toString();
  }
  return "";
};
