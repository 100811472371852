import API from 'api'
import { AxiosError } from 'axios'
import Section from 'components/common/Section'
import MainLayout from 'components/template/MainLayout'
import TextDescForm from 'components/template/TextDescForm'
import { useModal } from 'hook/useModal'
import useTMutation from 'hook/useTMutation'
import useGetQuery from 'hook/useTQuery'
import { GenericResponse } from 'model/common'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getQuery } from 'shared/common'
import { returnTextFigkDetailOption } from 'shared/queryOption/textFigk'

const TextDesc = () => {
    const textId = getQuery()['i']
    const navigate = useNavigate()
    const { Notice, closeNotice } = useModal()

    const textFigkSuccessCallback = (res: GenericResponse, status: string) => {
        if (res.code === 200) {
            Notice({
                icon: 'checked',
                type: 'alert',
                title: `송고가 완료되었습니다.`,
                content: `MY FIGK에서 확인하실 수 있습니다.`,
                onClickConfirm: () => {
                    closeNotice()
                    navigate(-1)
                },
            })
        }
    }

    const textFigkDeleteSuccessCallback = (res: GenericResponse, status: string) => {
        if (res.code === 200) {
            navigate(-1)
            // Notice({
            //     icon: 'checked',
            //     type: 'alert',
            //     title: `삭제가 완료되었습니다.`,
            //     content: ``,
            //     onClickConfirm: () => {
            //         closeNotice()
            //         navigate(-1)
            //     },
            // })
        }
    }
    const { data: textFigkDetail, error } = useGetQuery(
        ['@getTextFigkDetail'],
        () => API.TextFigk.getTextDetail(String(textId)),
        returnTextFigkDetailOption(textId)
    )
    const { mutate: tryTextDel, error: deleteTextError } = useTMutation(
        ['@deleteTextFigk', String(textId)],
        (id: string) => API.TextFigk.deleteTextFigk(id),
        (data: GenericResponse) => textFigkDeleteSuccessCallback(data, '삭제')
    )

    const { mutate: tryTextApply, error: applyTextError } = useTMutation(
        ['@applyTextFigk', String(textId)],
        (id: string) => API.TextFigk.applyTextFigk(id),
        (data: GenericResponse) => textFigkSuccessCallback(data, '송고'),
        {
            onError: (err: AxiosError) => {
                const error = err.response?.data as GenericResponse
                if (error.code === 409) {
                    Notice({
                        icon: 'bang',
                        type: 'alert',
                        title: '오류가 발생했습니다.',
                        content: error.message,
                        onClickConfirm: () => {
                            closeNotice()
                        },
                    })
                }
            },
        }
    )

    useEffect(() => {
        if (!textId) {
            Notice({
                icon: 'bang',
                type: 'alert',
                title: `잘못된 접근입니다.`,
                content: `이전 페이지로 이동됩니다.`,
                onClickConfirm: () => {
                    closeNotice()
                    navigate(-1)
                },
            })
        }
    }, [textId])

    useEffect(() => {
        if (error) {
            Notice({
                icon: 'bang',
                type: 'alert',
                title: `페이지를 불러오는 중 오류가 발생했습니다.`,
                content: `이전 페이지로 이동됩니다.`,
                onClickConfirm: () => {
                    closeNotice()
                    navigate(-1)
                },
            })
        }
    }, [error])

    return (
        <MainLayout>
            <Section>
                {textFigkDetail && (
                    <TextDescForm
                        data={textFigkDetail}
                        deleteTextFigk={() => tryTextDel(textId)}
                        applyTextFigk={() => tryTextApply(textId)}
                    />
                )}
            </Section>
        </MainLayout>
    )
}

export default TextDesc
