import ButtonBox from "components/common/Modal/ModalButtonBox";
import Container from "components/common/Modal/ModalContainer";
import Contents from "components/common/Modal/ModalContents";
import Header from "components/common/Modal/ModalHeader";
export const Modal = {
  Container,
  Contents,
  Header,
  ButtonBox,
};
