import { apiHeader } from 'api/constant'
import createUrlParam from 'hook/useCreateQuery'
import { GenericResponse, HomeTextFigkFilterOption } from 'model/common'
import { CreateTextFigkRequest } from 'model/textFigk'

const TextFigk = {
    getTextFigk: async (search?: HomeTextFigkFilterOption) => {
        const up = createUrlParam(search)
        const response = await apiHeader(true, 'b').get<GenericResponse>(`text-figk${up}`)
        return response.data
    },

    getTextDetail: async (i: string) => {
        const response = await apiHeader(true, 'b').get<GenericResponse>(`text-figk`, { params: { id: i } })
        return response.data
    },

    createTextFigk: async (data: CreateTextFigkRequest) => {
        const response = await apiHeader(true, 'b').post<GenericResponse>(`text-figk`, data)
        return response.data
    },

    updateTextFigk: async (data: CreateTextFigkRequest, i?: number) => {
        let addIdData = Object.assign(data, { id: i })

        const response = await apiHeader(true, 'b').put<GenericResponse>(`text-figk`, addIdData)
        return response.data
    },

    deleteTextFigk: async (i: string) => {
        const response = await apiHeader(true, 'b').delete<GenericResponse>(`text-figk`, { data: { id: i } })
        return response.data
    },

    applyTextFigk: async (i: string) => {
        const response = await apiHeader(true, 'b').put<GenericResponse>(`text-figk/apply`, { id: i })
        return response.data
    },

    validMaxApply: async () => {
        const response = await apiHeader(true, 'b').post<GenericResponse>(`text-figk/max-apply`)
        return response.data
    },

    getApplyTextFigkList: async () => {
        const response = await apiHeader(true, 'b').get<GenericResponse>(`text-figk/apply-list`)
        return response.data
    },
}

export default TextFigk
