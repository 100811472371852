import axios from 'axios'
import { getCookie, getStorage } from 'util/storage'

//export const BASE_URL = 'http://192.168.0.72:3100/'
// export const BASE_URL = 'http://3.38.254.98/api/figk-studio'
// export const COMMON_API_URL = 'http://3.38.254.98/api/figk-common'
// export const BASE_URL = 'http://localhost:3100/figk-studio'
// export const COMMON_API_URL = 'http://localhost:3100/figk-common'
export const BASE_URL = 'https://api.fig-zone.com/figk-studio'
export const COMMON_API_URL = 'https://api.fig-zone.com/figk-common'

export const apiHeader = (isAccess: boolean, uType: 'b' | 'c', isForm?: boolean) => {
    return axios.create({
        baseURL: uType === 'b' ? BASE_URL : COMMON_API_URL,
        headers: {
            Authorization: `Bearer ${isAccess ? getCookie('access') ?? '' : getStorage('refresh')}`,
            'Content-Type': isForm ? 'multipart/form-data' : 'application/json',
        },
    })
}
