import FilterSearch from 'components/common/FilterSearch'
import Flex from 'components/common/Flex'
import ListCountAndSort from 'components/common/ListCountAndSort'
import PageTitle from 'components/common/PageTitle'
import PeriodDatepicker from 'components/common/PeriodDatepicker'
import Section from 'components/common/Section'
import Select from 'components/common/Select'
import { Table, TBody, Td, Th, THead, Tr } from 'components/common/Table'
import Filter from 'components/template/Filter'
import MainLayout from 'components/template/MainLayout'
import { MobileSettlementTable } from 'components/template/MobileSettlementTableCard/index.styled'
import SettlementDashboard from 'components/template/SettlementDashboard'
import useGap from 'hook/useGap'
import { useModal } from 'hook/useModal'
import useWidth from 'hook/useWidth'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTER_PATH } from 'router'
import { SettlementList } from './index.styled'

const Settlement = () => {
    const navigate = useNavigate()
    const { width } = useWidth()
    const { gap } = useGap()
    const { Modal } = useModal()
    //filter search 임시 스테이트 필터컴포넌트 사용예시
    const [value, setValue] = useState<string>('')
    //테이블 반응형 조건
    const TableRenderCondition = width > 819 ? true : false

    const detailPageHandler = () => {
        navigate(ROUTER_PATH.DETAIL_SETTLEMENT)
    }
    return (
        <MainLayout>
            <Section>
                <Flex padding_block_end={gap().pageTitle}>
                    <PageTitle
                        title='정산 관리'
                        subject={<>정산관리 서브타이틀이 들어갑니다.</>}
                    />
                </Flex>
                <Filter
                    filterSearch={
                        <FilterSearch
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    }>
                    {/* '정산 상태 전체', '완료', '대기', '취소' */}
                    <Select options={[]} />
                    <PeriodDatepicker getDate={(e, s) => {}} />
                </Filter>
                <SettlementDashboard />

                <ListCountAndSort
                    totalCount={0}
                    sortHandler={() => {}}
                />
                <Flex padding_block_end={gap().listWithPagination}>
                    {TableRenderCondition && (
                        <Table>
                            <colgroup>
                                <col width={100} />
                                <col />
                                <col width={'20%'} />
                                <col width={140} />
                            </colgroup>
                            <THead>
                                <Tr>
                                    <Th>정산 상태</Th>
                                    <Th>내역</Th>
                                    <Th>총 정산금</Th>
                                    <Th>정산 완료일</Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {Array(10)
                                    .fill('fill')
                                    .map((item, index) => {
                                        //상태 예제 ex)
                                        // 완료(complete)
                                        // 대기(light_gray)
                                        return (
                                            <Tr
                                                key={index}
                                                onClick={detailPageHandler}>
                                                <Td color='complete'>완료</Td>
                                                <Td
                                                    color='black10'
                                                    align='left'>
                                                    Vol.023 투표 보상금
                                                </Td>
                                                <Td
                                                    className='fw500'
                                                    color='black20'
                                                    align='right'>
                                                    100,000
                                                </Td>
                                                <Td color='date'>22.12.23 21:00</Td>
                                            </Tr>
                                        )
                                    })}
                            </TBody>
                        </Table>
                    )}
                    {/* 모바일 카드 리스트  */}
                    {!TableRenderCondition && (
                        <Flex direction='column'>
                            {Array(10)
                                .fill('fill')
                                .map((item, index) => {
                                    //상태 예제 ex)
                                    // 완료(complete)
                                    // 대기(light_gray)
                                    return (
                                        <MobileSettlementTable.Container
                                            key={index}
                                            onClick={detailPageHandler}>
                                            <SettlementList>
                                                <div className='left'>
                                                    <MobileSettlementTable.Status
                                                        className='status'
                                                        color='complete'>
                                                        완료
                                                    </MobileSettlementTable.Status>
                                                    <span className='contents'>Vol.023 투표 보상금</span>
                                                    <span className='date'>22.12.23 21:00</span>
                                                </div>
                                                <div className='right'>
                                                    <span className='settlement'>11,000원</span>
                                                </div>
                                            </SettlementList>
                                        </MobileSettlementTable.Container>
                                    )
                                })}
                        </Flex>
                    )}
                </Flex>
                {/* <Pagination /> */}
            </Section>
        </MainLayout>
    )
}

export default Settlement
