import styled from "styled-components";

export const SettlementList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .left {
    display: flex;
    flex-direction: column;
    .status {
      padding-bottom: 6px;
    }
    .contents {
      font-size: 1.5rem;
      color: ${(p) => p.theme.textColor.textColor70};
      padding-bottom: 8px;
    }
  }
  .right {
  }
`;
