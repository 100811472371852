import styled from "styled-components";
import { mediaQuery } from "theme/mediaQuery";
import { mixin } from "theme/mixin";
import bangIcon from "assets/icon/bang_circle.svg";
const StringVerticalBarItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  ${mixin.StatusColor}

  &:not(:last-of-type):after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 9px;
    margin-inline: 8px;
    background-color: ${(p) => p.theme.palette.gray20};
  }
`;

const StringVerticalBarList = styled.ul`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
`;

const TotalSummary = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  background-color: #f2f5f6;
  padding: 24px 30px;
  border-radius: 8px;
  border: 1px solid #e6e9eb;
  margin-bottom: 20px;

  &:before {
    content: attr(data-title);
    color: ${(p) => p.theme.palette.blue_gray};
    font-size: 1.5rem;
    font-weight: 600;
  }
  .contents {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: inherit;
    .chipBox {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      gap: 3px;
      .chip {
        display: inline-block;
        padding: 5px 8px;
        font-weight: 600;
        font-size: 1.2rem;
        background-color: rgba(91, 104, 121, 0.1);
        color: rgba(91, 104, 121, 0.7);
        border-radius: 4px;
      }
    }
    .total {
      color: ${(p) => p.theme.palette.black20};
      font-size: 2.2rem;
      font-weight: 700;
    }
  }
  ${mediaQuery("tablet")} {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 20px;
    .contents {
      width: 100%;
      flex: initial;
      gap: 12px;
      flex-direction: column-reverse;
      align-items: flex-end;
    }
  }
`;

const LogNotice = styled.div`
  padding: 40px 30px 0;
  header {
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${(p) => p.theme.textColor.textColor80};
    font-size: 1.3rem;
    padding-bottom: 10px;
    &:before {
      content: "";
      display: block;
      background-image: url(${bangIcon});
      width: 11px;
      height: 11px;
      background-size: 100%;
    }
  }
  p {
    line-height: 1.5;
    font-size: 1.2rem;
    color: ${(p) => p.theme.textColor.textColor40};
    word-break: break-all;
    &:not(:last-of-type) {
      padding-bottom: 6px;
    }
  }
  ${mediaQuery("tablet")} {
    padding-inline: 0;
    padding-top: 24px;
  }
`;

export const PS = {
  StringVerticalBarList,
  StringVerticalBarItem,
  TotalSummary,
  LogNotice,
};
