import { apiHeader } from 'api/constant'
import { GenericResponse } from 'model/common'

const Tag = {
    getTagsList: async (word: string) => {
        const response = await apiHeader(true, 'c').get<GenericResponse>(`tag?word=${word}`)
        return response.data
    },
}

export default Tag
