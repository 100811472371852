import API from 'api'
import Flex from 'components/common/Flex'
import PageTitle from 'components/common/PageTitle'
import Pagination from 'components/common/Pagination'
import Section from 'components/common/Section'
import SkeletonNoticeArccordion from 'components/skeleton/SkeletonNoticeArccordion'
import MainLayout from 'components/template/MainLayout'
import NoticeArccordion from 'components/template/NoticeArccordion'
import { useDebounce } from 'hook/useDebounce'
import useGap from 'hook/useGap'
import useGetQuery from 'hook/useTQuery'
import { NoticeFilterOption } from 'model/common'
import { useCallback, useEffect, useState } from 'react'
import { NOTICE_PER, SKELETON_DELAY, noticeSearchRequestInitData } from 'shared/common'
import { returnNoticeOption } from 'shared/queryOption/notice'

const NoticeBoard = () => {
    const { gap, calcGap } = useGap()

    const [skeletonState, setSkeletonState] = useState<boolean>(true)
    const [noticeFilter, setNoticeFilter] = useState<NoticeFilterOption>(noticeSearchRequestInitData)
    const { data: notice, isFetching } = useGetQuery(
        ['@getNoticeList', JSON.stringify(noticeFilter)],
        () => API.Notice.getNoticeList(noticeFilter),
        returnNoticeOption
    )
    const setPage = (e: number) => {
        setNoticeFilter({ ...noticeFilter, page: e })
    }

    const skeletonDelay = useCallback(
        useDebounce(() => setSkeletonState(false), SKELETON_DELAY),
        []
    )

    useEffect(() => {
        setSkeletonState(true)

        skeletonDelay()
    }, [noticeFilter])

    return (
        <MainLayout>
            <Section>
                <Flex
                    direction='column'
                    padding_block_end={calcGap('tablet', '30px', '40px')}>
                    <PageTitle
                        title='공지사항'
                        subject='FIGK 활동에 중요한 안내 사항을 확인할 수 있습니다.'
                    />
                </Flex>
                <Flex
                    direction='column'
                    padding_block_end={gap().listWithPagination}>
                    {skeletonState || isFetching ? <SkeletonNoticeArccordion /> : <NoticeArccordion data={notice} />}
                    {/* {!notice && <SkeletonNoticeArccordion />}
                    {notice && <NoticeArccordion data={notice} />} */}
                    {/* <NoticeArccordion data={notice} /> */}
                </Flex>
                <Pagination
                    totalItem={notice?.totalCount ?? 0}
                    setCurrentPage={setPage}
                    currentPage={noticeFilter.page}
                    limit={NOTICE_PER}
                />
            </Section>
        </MainLayout>
    )
}

export default NoticeBoard
