import { apiHeader } from 'api/constant'
import { GenericResponse } from 'model/common'
import { AuthorJoinModel } from 'recoil/authorJoin/authorJoin'

const Join = {
    getTerms: async () => {
        const response = await apiHeader(true, 'b').get<GenericResponse>(`join/term`)
        return response.data
    },

    getAuthorByCode: async (code: string) => {
        const response = await apiHeader(true, 'b').get<GenericResponse>(`join`, { params: { code } })
        return response.data
    },

    joinAuthor: async (data: AuthorJoinModel) => {
        const response = await apiHeader(true, 'b').post<GenericResponse>(`join`, data)
        return response.data
    },
}

export default Join
