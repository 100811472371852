import { useAuth } from "AuthProvider";
import ActionButton from "components/common/ActionButton";
import Flex from "components/common/Flex";
import InputLabel from "components/common/InputLabel";
import InputText from "components/common/InputText";
import PageTitle from "components/common/PageTitle";
import Section from "components/common/Section";
import Textarea from "components/common/Textarea";
import { JoinTypes } from "components/layout/Form/CoverLetter";
import MainLayout from "components/template/MainLayout";
import UserInfo from "components/template/MyInfo/UserInfo";
import { useModal } from "hook/useModal";
import useWidth from "hook/useWidth";
import { formVariants } from "pages/Form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useResetRecoilState } from "recoil";
import { $homeTextFigkFilterOption } from "recoil/filter/homeTextFigkFilterOption";
import { $voteFilterOption } from "recoil/filter/voteFilterOption";
import { ROUTER_PATH } from "router";
import { VALIDATION } from "shared/validation";
import { removeCookie, removeStorage } from "util/storage";
import { PS } from "./index.styled";
import ChangePassword from "components/common/ChangePassword";

const Profile = () => {
  const { user } = useAuth();
  const { Modal, Notice, closeNotice } = useModal();
  const { media } = useWidth();
  const navigate = useNavigate();
  const resetHomeFilter = useResetRecoilState($homeTextFigkFilterOption);
  const resetVoteFilter = useResetRecoilState($voteFilterOption);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<JoinTypes>({
    defaultValues: {
      nickName: "",
      introductory_text: "", //소개글
      instagram: "",
      blog: "",
      homepage: "",
    },
  });

  const CompleteConfirmHandler = () => {
    closeNotice();
    setTimeout(() => {
      navigate(ROUTER_PATH.LOGIN);
    }, 300);
  };
  const onSubmit = () => {};

  const filterReset = () => {
    resetHomeFilter();
    resetVoteFilter();
  };

  const logout = () => {
    filterReset();
    removeCookie("access");
    removeStorage("refresh");
    window.location.replace("/");
  };

  const openChangePassword = () => {
    Modal({
      bottomSheet: true,
      content: <ChangePassword />,
    });
  };

  return (
    <MainLayout>
      <Section>
        <Flex padding_block_end={"60px"}>
          <PageTitle
            title="프로필관리"
            subject="계정, 개인정보 변경은 figk@fig.xyz으로 문의해주세요."
          />
        </Flex>
      </Section>
      <Section>
        <PS.ProfileInfoContainer>
          <div className="infoBox">
            <UserInfo
              currentGroup={user?.currentGroup ?? ""}
              nickname={user?.name ?? ""}
            />
            <p>{user?.email}</p>
            <div className="chipBox">
              <div className="chip">
                <strong>휴대폰번호 : </strong>010-1234-1234
              </div>
              <div className="chip">
                <strong>계좌번호 : </strong>신한 100-100-1000000
              </div>
            </div>
          </div>
          <div className="sideBox">
            <ActionButton
              size={media.mobile ? "notice" : "cta"}
              mode="line"
              onClick={openChangePassword}
            >
              비밀번호 변경
            </ActionButton>
          </div>
        </PS.ProfileInfoContainer>

        {/* <ProfileMyInfo data={user ?? null}></ProfileMyInfo> */}
      </Section>
      <Section>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" gap={34}>
            <Flex direction="column" gap={formVariants.gap.label}>
              <InputLabel>
                필명<span>*미작성시 이름으로 등록됩니다.</span>
              </InputLabel>
              <InputText
                name="nickName"
                control={control}
                rules={{
                  required: "비밀번호를 입력하세요.",
                  pattern: {
                    value: VALIDATION.EMAIL,
                    message: "비밀번호 형식이 아닙니다.",
                  },
                }}
                placeholder="최대 30자 입력가능"
                errorMessage={errors.nickName?.message as string}
              >
                {/* {!checkNickName.checking && (
                <InputRoundButton
                  isSuccess={checkNickName.success}
                  mode="solid"
                  onClick={nickCheckHandler}
                >
                  중복
                </InputRoundButton>
              )} */}
              </InputText>
            </Flex>
            <Flex direction="column" gap={formVariants.gap.label}>
              <InputLabel>소개글</InputLabel>

              <Textarea
                name="introductory_text"
                control={control}
                rules={{
                  required: "비밀번호를 입력하세요.",
                  pattern: {
                    value: VALIDATION.EMAIL,
                    message: "비밀번호 형식이 아닙니다.",
                  },
                }}
                placeholder="최대 150자 입력가능"
                errorMessage={errors.introductory_text?.message as string}
              />
            </Flex>
            <Flex direction="column" gap={formVariants.gap.label}>
              <InputLabel>인스타그램</InputLabel>
              <InputText
                name="instagram"
                control={control}
                rules={{
                  required: "비밀번호를 입력하세요.",
                  pattern: {
                    value: VALIDATION.EMAIL,
                    message: "비밀번호 형식이 아닙니다.",
                  },
                }}
                placeholder="https://"
                errorMessage={errors.instagram?.message as string}
              />
            </Flex>
            <Flex direction="column" gap={formVariants.gap.label}>
              <InputLabel>블로그</InputLabel>

              <InputText
                name="blog"
                control={control}
                rules={{
                  required: "비밀번호를 입력하세요.",
                  pattern: {
                    value: VALIDATION.EMAIL,
                    message: "비밀번호 형식이 아닙니다.",
                  },
                }}
                placeholder="https://"
                errorMessage={errors.blog?.message as string}
              />
            </Flex>
            <Flex direction="column" gap={formVariants.gap.label}>
              <InputLabel>홈페이지</InputLabel>
              <InputText
                name="homepage"
                control={control}
                rules={{
                  required: "비밀번호를 입력하세요.",
                  pattern: {
                    value: VALIDATION.EMAIL,
                    message: "비밀번호 형식이 아닙니다.",
                  },
                }}
                placeholder="https://"
                errorMessage={errors.homepage?.message as string}
              />
            </Flex>
            <PS.LogoutButton type="button" onClick={logout}>
              로그아웃
            </PS.LogoutButton>
          </Flex>
          <PS.ButtonBox>
            <ActionButton
              mode="line"
              size="bigCta"
              onClick={() => navigate(ROUTER_PATH.JOIN)}
            >
              취소
            </ActionButton>
            <ActionButton
              type="button"
              size="bigCta"
              onClick={() => {
                Notice({
                  icon: "checked",
                  type: "alert",
                  title: "회원가입 완료",
                  content:
                    "회원가입이 완료되었습니다.\n로그인 후 이용해주세요.",
                  onClickConfirm: CompleteConfirmHandler,
                });
              }}
            >
              프로필 저장
            </ActionButton>
          </PS.ButtonBox>
        </form>
      </Section>
    </MainLayout>
  );
};

export default Profile;
