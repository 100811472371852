import { apiHeader } from "api/constant";
import { GenericResponse } from "model/common";
import moment from "moment";
import { useEffect, useState } from "react";
import { convertZeroToInclude } from "shared/convert";
import useGetQuery from "./useTQuery";
import { useAuth } from "AuthProvider";

export interface CountdownCronSetting {
  closingVoteDay: number;
  closingVoteHour: number;
  sendingJudgementDay: number;
  sendingJudgementHour: number;
  startVoteDay: number;
  startVoteHour: number;
  textFigkApplyDay: number;
  textFigkApplyHour: number;
}

const Countdown = () => {
  const [countdown, setCountdown] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const { user } = useAuth();
  const getCronSetting = async () => {
    const response = await apiHeader(true, "b").get<GenericResponse>(
      `/text-figk/cron-setting`
    );
    return response.data;
  };

  const { data, isLoading } = useGetQuery(
    ["@getCronSetting"],
    () => getCronSetting(),
    {
      select: (res: GenericResponse) => {
        let data: CountdownCronSetting | undefined = res.data;
        return data;
      },
    }
  );

  const 월 = 1;
  const 화 = 2;
  const 수 = 3;
  const 목 = 4;
  const 금 = 5;
  const 토 = 6;
  const 일 = 0;

  useEffect(() => {
    if (data) {
      const calculateCountdown = () => {
        const {
          closingVoteDay,
          closingVoteHour,
          sendingJudgementDay,
          sendingJudgementHour,
          startVoteDay,
          startVoteHour,
          textFigkApplyDay,
          textFigkApplyHour,
        } = data;

        const now = moment();

        /**
         * 상태별 담주 판단
         */
        const sendingNextWeek =
          now.day() <= sendingJudgementDay
            ? sendingJudgementDay
            : sendingJudgementDay + 7;
        //    ;
        const votingNextWeek =
          now.day() <= closingVoteDay ? closingVoteDay : closingVoteDay + 7;
        //    ;
        const judgementNextWeek =
          now.day() <= startVoteDay ? startVoteDay : startVoteDay + 7;
        //    ;

        // 상태별 마감시간
        let targetDate;

        if (user?.processStatus === "A") {
          setStatus(user?.processStatus);
          targetDate = moment()
            .day(sendingNextWeek)
            .hour(sendingJudgementHour)
            .minute(0)
            .second(0);
        } else if (user?.processStatus === "V") {
          setStatus(user?.processStatus);

          targetDate = moment()
            .day(votingNextWeek)
            .hour(closingVoteHour)
            .minute(0)
            .second(0);
        } else if (user?.processStatus === "T") {
          setStatus(user?.processStatus);
          targetDate = moment()
            .day(judgementNextWeek)
            .hour(startVoteHour)
            .minute(0)
            .second(0);
        } else {
          //지
          setCountdown("0");
          return;
        }

        const duration = moment.duration(targetDate.diff(now));
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        const countdownString = `${days}일 ${convertZeroToInclude(
          hours
        )}:${convertZeroToInclude(minutes)}:${convertZeroToInclude(seconds)}`;
        setCountdown(countdownString);
      };

      calculateCountdown();

      const interval = setInterval(calculateCountdown, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [data, user?.processStatus]);

  return { status, countdown };
};

export default Countdown;
