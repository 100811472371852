import { apiHeader } from 'api/constant'
import { GenericResponse, NoticeFilterOption } from 'model/common'

const Notice = {
    getNoticeList: async (filter: NoticeFilterOption) => {
        const response = await apiHeader(true, 'b').get<GenericResponse>(`notice`, { params: filter })
        return response.data
    },
}

export default Notice
