import Flex from "components/common/Flex";
import PageTitle from "components/common/PageTitle";
import Section from "components/common/Section";
import MainLayout from "components/template/MainLayout";
import useGap from "hook/useGap";
import { PS } from "./indexd.styled";
import { DetailSettlementLog } from "components/template/DetailSettlementLog";

const TEST = {
  type: "송고",
  text: " MS가 오피스 코파일럿을 발표했다. 우리가 일하는 방식은 바뀔 것이다. MS가 오피스 코파일럿을 발표했다. 우리가 일하는 방식은 바뀔 것이다. MS가 오피스 코파일럿을 발표했다. 우리가 일하는 방식은 바뀔 것이다. MS가 오피스 코파일럿을 발표했다. 우리가 일하는 방식은 바뀔 것이다.",
  amount: "40,000",
};

const DetailSettlement = () => {
  const { gap } = useGap();
  return (
    <MainLayout>
      <Section>
        <PS.StringVerticalBarList>
          <PS.StringVerticalBarItem color="complete" fontweight="500">
            완료
          </PS.StringVerticalBarItem>
          <PS.StringVerticalBarItem color="date">
            22.12.23 21:00
          </PS.StringVerticalBarItem>
        </PS.StringVerticalBarList>
        <Flex padding_block_end={gap().pageTitle}>
          <PageTitle title="Vol.023 정산 상세" />
        </Flex>
        <PS.TotalSummary data-title="총 정산금">
          <div className="contents">
            <div className="chipBox">
              <span className="chip">송고2</span>
              <span className="chip">투표1</span>
              <span className="chip">발행2</span>
            </div>
            <span className="total">80,000원</span>
          </div>
        </PS.TotalSummary>

        <DetailSettlementLog.Container total={5}>
          <DetailSettlementLog.List>
            <DetailSettlementLog.Item data={TEST} />
            <DetailSettlementLog.Item data={TEST} />
          </DetailSettlementLog.List>
          <DetailSettlementLog.List>
            <DetailSettlementLog.Item data={TEST} />
            <DetailSettlementLog.Item data={TEST} />
          </DetailSettlementLog.List>
          <DetailSettlementLog.List>
            <DetailSettlementLog.Item data={TEST} />
            <DetailSettlementLog.Item data={TEST} />
          </DetailSettlementLog.List>
        </DetailSettlementLog.Container>
        <PS.LogNotice>
          <header>정산 입금안내</header>
          <p>송고 건 당 30,000원 / 투표 건 당 20,000원 / 당선 건 당 40,000원</p>
          <p>
            정산 안내글이 들어갑니다. 정산 안내글이 들어갑니다. 정산 안내글이
            들어갑니다. 정산 안내글이 들어갑니다.
          </p>
        </PS.LogNotice>
      </Section>
    </MainLayout>
  );
};
export default DetailSettlement;
