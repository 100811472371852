import { useAuth } from 'AuthProvider'
import API from 'api'
import ActionButton from 'components/common/ActionButton'
import Flex from 'components/common/Flex'
import PageTitle from 'components/common/PageTitle'
import Section from 'components/common/Section'
import SkeletonSendingList from 'components/skeleton/SkeletonSendingList'
import MainLayout from 'components/template/MainLayout'
import SendingListComponent from 'components/template/SendingList'
import { Vol } from 'components/template/VotingReminder/index.styled'
import { useDebounce } from 'hook/useDebounce'
import useGap from 'hook/useGap'
import useGetQuery from 'hook/useTQuery'
import useWidth from 'hook/useWidth'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTER_PATH } from 'router'
import { SKELETON_DELAY } from 'shared/common'
import { 회차 } from 'shared/convert'
import { returnSendingListOption } from 'shared/queryOption/sendingList'
import styled from 'styled-components'

const PageTitleWrap = styled.div`
    display: flex;
    justify-content: space-between;
`
const SendingList = () => {
    const { gap } = useGap()
    const { user } = useAuth()
    const { media } = useWidth()
    const navigate = useNavigate()
    const [skeletonState, setSkeletonState] = useState<boolean>(true)

    const { data: list, isFetching } = useGetQuery(
        ['@getApplyTextFigkList'],
        () => API.TextFigk.getApplyTextFigkList(),
        returnSendingListOption
    )

    const skeletonDelay = useCallback(
        useDebounce(() => setSkeletonState(false), SKELETON_DELAY),
        []
    )

    useEffect(() => {
        setSkeletonState(true)

        skeletonDelay()
    }, [isFetching])

    return (
        <MainLayout innerPadding='exceptional'>
            <Section>
                <Flex
                    direction='column'
                    gap={media.mobile ? '16px' : '24px'}
                    alignitems='flex-start'
                    padding={media.mobile ? '0 0 40px' : '0 0 50px'}>
                    <Vol className='point1'>Vol.{회차(user?.textFigkWeek)}</Vol>
                    <PageTitle
                        title='FIGK 송고 리스트'
                        subject={<>핔커들이 송고한 글을 확인할 수 있습니다.</>}
                        sideButton={
                            <ActionButton
                                mode={'solid'}
                                size={media.mobile ? 'notice' : 'cta'}
                                onClick={() => {
                                    navigate(ROUTER_PATH.WRITING, { state: { type: 'S' } })
                                }}>
                                송고하러 가기
                            </ActionButton>
                        }
                    />
                </Flex>

                {skeletonState || isFetching ? (
                    <SkeletonSendingList />
                ) : (
                    <>
                        <Flex
                            direction='column'
                            gap={12}
                            padding_block_end={gap().listWithPagination}>
                            {list &&
                                list.map((i) => {
                                    return (
                                        <SendingListComponent
                                            key={`sendList_${i.id}`}
                                            data={i}
                                        />
                                    )
                                })}
                        </Flex>
                    </>
                )}
            </Section>
        </MainLayout>
    )
}

export default SendingList
